/**
 * @file Global
 */

import svg4everybody from 'svg4everybody';

jQuery.noConflict();
( function( $ ) {
	const pageWidth = function() {
		return $( window ).width();
	};

	$( document ).ready( function() {
		svg4everybody();

		// scroll to
		if ( $( 'a.v-intro__link' ) ) {
			$( 'a.v-intro__link' ).click( function( event ) {
				const sectionID = $( this ).attr( 'href' );
				if ( sectionID.indexOf( '#' ) !== 0 ) {
					return;
				}
				event.preventDefault();
				const hashID = sectionID.substring( 1 );
				$( 'html, body' ).animate( {
					scrollTop: $( '#' + hashID ).offset().top,
				}, 1000 );
			} );
		}

		if ( $( 'a.v-utility-form__anchor' ) ) {
			$( 'a.v-utility-form__anchor' ).click( function( event ) {
				const sectionID = $( this ).attr( 'href' );
				if ( sectionID.indexOf( '#' ) !== 0 ) {
					return;
				}
				event.preventDefault();
				const hashID = sectionID.substring( 1 );
				$( 'html, body' ).animate( {
					scrollTop: $( '#' + hashID ).offset().top - 60,
				}, 1000 );
			} );
		}

		if ( ! sessionStorage.getItem( 'alreadyPopped_covid' ) ) {
			/**
			 *
			 */
			function popUp() {
				$( '#pop-up' ).addClass( 'open' );
				$( '#pop-up-container' ).addClass( 'open' );
				$( '#pop-up-container .pop-up-box' ).addClass( 'open' );
			}
			setTimeout( popUp, 1000 );
			sessionStorage.setItem( 'alreadyPopped_covid', 1 );
		}

		// closes pop-up

		$( '#pop-up, #pop-up-container .pop-up-box .close-pop' ).click( function() {
			$( '#pop-up' ).removeClass( 'open' );
			$( '#pop-up-container' ).removeClass( 'open' );
			$( '#pop-up-container .pop-up-box' ).removeClass( 'open' );
		} );

		const menuHalf = $( '.mobile-menu' ).height() / 2;

		const mobileMenu = $( '.mobile-menu' ).paperfold( {
			folds:         1,
			maxFoldHeight: 64,
		} );
		// create paperfold object in a conditional, per screen width that changes the mobile menu height - default, 767, etc

		// Mobile Int Banners
		const bannerURL = $( '#int-banner' ).attr( 'data-bg' );
		if ( pageWidth() <= 767 ) {
			$( '#int-banner' ).css( 'background-image', "url('" + bannerURL + "')" );
		} else {
			$( '#int-banner' ).removeAttr( 'style' );
		}

		// Mobile Nav Click Event
		$( '.mobile-button' ).click( function() {
			if ( $( '.v-header__menu-mobile' ).eq( 0 ).hasClass( 'open' ) ) {
				// body overflow
				$( 'body' ).css( 'overflow', 'visible' );

				// close menu
				$( '.v-header__menu-trigger' ).eq( 0 ).addClass( 'close' );
				$( '.v-header__menu-trigger' ).eq( 0 ).removeClass( 'open' );

				$( '.v-header__menu-mobile' ).eq( 0 ).addClass( 'close' );
				$( '.v-header__menu-mobile' ).eq( 0 ).removeClass( 'open' );

				$( '.v-mobile-overlay' ).eq( 0 ).addClass( 'close' );
				$( '.v-mobile-overlay' ).eq( 0 ).removeClass( 'open' );

				$.each( $( '.v-header__menu-trigger-bar' ), function( i, el ) {
					$( el ).addClass( 'close' );
					$( el ).removeClass( 'open' );
				} );
			} else {
				// body overflow
				$( 'body' ).css( 'overflow', 'hidden' );

				// open menu
				$( '.v-header__menu-trigger' ).eq( 0 ).addClass( 'open' );
				$( '.v-header__menu-trigger' ).eq( 0 ).removeClass( 'close' );

				$( '.v-header__menu-mobile' ).eq( 0 ).addClass( 'open' );
				$( '.v-header__menu-mobile' ).eq( 0 ).removeClass( 'close' );

				$( '.v-mobile-overlay' ).eq( 0 ).addClass( 'open' );
				$( '.v-mobile-overlay' ).eq( 0 ).removeClass( 'close' );

				$.each( $( '.v-header__menu-trigger-bar' ), function( i, el ) {
					$( el ).addClass( 'open' );
					$( el ).removeClass( 'close' );
				} );
			}
			// $('.mobile-menu').toggleClass('open');
			mobileMenu.toggle();
		} );

		// Other Services Click Event
		$( '.vjs-intro__other-services' ).click( function() {
			document.body.classList.add( 'other-services-open' );

			// body overflow
			$( 'body' ).css( 'overflow', 'hidden' );

			// open menu
			$( '.v-intro__trigger' ).eq( 0 ).addClass( 'open' );
			$( '.v-intro__trigger' ).eq( 0 ).removeClass( 'close' );

			$( '.v-intro__mobile' ).eq( 0 ).addClass( 'open' );
			$( '.v-intro__mobile' ).eq( 0 ).removeClass( 'close' );

			$( '.v-mobile-overlay' ).eq( 0 ).addClass( 'open' );
			$( '.v-mobile-overlay' ).eq( 0 ).removeClass( 'close' );

			$.each( $( '.v-intro__trigger-bar' ), function( i, el ) {
				$( el ).addClass( 'open' );
				$( el ).removeClass( 'close' );
			} );
		} );

		$( '.other-services-close-button' ).click( function() {
			if ( document.body.classList.contains( 'other-services-open' ) ) {
				// body overflow
				$( 'body' ).css( 'overflow', 'visible' );

				// close menu
				$( '.v-intro__trigger' ).eq( 0 ).addClass( 'close' );
				$( '.v-intro__trigger' ).eq( 0 ).removeClass( 'open' );

				$( '.v-intro__mobile' ).eq( 0 ).addClass( 'close' );
				$( '.v-intro__mobile' ).eq( 0 ).removeClass( 'open' );

				$( '.v-mobile-overlay' ).eq( 0 ).addClass( 'close' );
				$( '.v-mobile-overlay' ).eq( 0 ).removeClass( 'open' );

				$.each( $( '.v-intro__trigger-bar' ), function( i, el ) {
					$( el ).addClass( 'close' );
					$( el ).removeClass( 'open' );
				} );

				document.body.classList.remove( 'other-services-open' );
			}
		} );

		// Subnav Click Event
		$( '.mobile-subnav h4' ).click( function() {
			if ( ! $( '.mobile-subnav' ).hasClass( 'open' ) ) {
				$( '.mobile-subnav ul' ).slideToggle( '500', function() {
					$( '.mobile-subnav' ).addClass( 'open' );
				} );
			} else {
				$( '.mobile-subnav ul' ).slideToggle( '500', function() {
					$( '.mobile-subnav' ).removeClass( 'open' );
				} );
			}
		} );

		document.addEventListener( 'wpcf7submit', function( event ) {
			if ( '885' === event.detail.contactFormId ) {
				//Automation
				ga( 'send', 'event', 'Contact Lead', 'Click', 'Automation Form' );
			} else if ( '428' === event.detail.contactFormId ) {
				//Distibutors
				ga( 'send', 'event', 'Contact Lead', 'Click', 'Distributors Form' );
			} else if ( '721' === event.detail.contactFormId ) {
				//End User
				ga( 'send', 'event', 'Contact Lead', 'Click', 'End User Form' );
			} else if ( '616' === event.detail.contactFormId ) {
				//Job Application Form
				ga( 'send', 'event', 'Contact Lead', 'Click', 'Job Application Form' );
			} else if ( '357' === event.detail.contactFormId ) {
				//Packaging Analysis
				ga( 'send', 'event', 'Contact Lead', 'Click', 'Packaging Analysis Form' );
			} else if ( '877' === event.detail.contactFormId ) {
				//Products & Services CTA
				ga( 'send', 'event', 'Contact Lead', 'Click', 'Products & Services Form' );
			} else if ( '599' === event.detail.contactFormId ) {
				//Solutions Sidebar Contact
				ga( 'send', 'event', 'Contact Lead', 'Click', 'Solutions Form' );
			} else { }
		}, false );
	} );

	$( window ).bind( 'resize', function() {
		if ( pageWidth() >= 1024 ) {
			if ( $( '.mobile-menu' ).hasClass( 'open' ) ) {
				mobileMenu.toggle();
				$( '.mobile-menu' ).toggleClass( 'open' );
			}
		}

		// Mobile Int Banners
		const bannerURL = $( '#int-banner' ).attr( 'data-bg' );
		if ( pageWidth() <= 767 ) {
			$( '#int-banner' ).css( 'background-image', "url('" + bannerURL + "')" );
		} else {
			$( '#int-banner' ).removeAttr( 'style' );
		}
	} );
}( jQuery ) );
